import * as React from 'react';

import { useGetUserQuery } from 'store/api';

import { useCustomRouter } from 'hooks';

import Aside from 'components/aside';
import Card from 'components/card';
import { CardSize } from 'components/card/type';
import Header from 'components/header';
import NoAccess from 'components/noAccess';

import { Links } from 'types/route';

import styles from './layout.module.scss';

const Layout: React.FC = (props) => {
  const { pathname } = useCustomRouter();

  const { children } = props;

  const { data: user } = useGetUserQuery();

  const informationLink = Links.company + Links.information;

  const isInformationPage = pathname.includes(informationLink);

  if (!user) return null;

  const isNoAccess = !user.role.adminPanelAccess;

  if (isNoAccess) {
    return <NoAccess />;
  }

  return (
    <>
      <Header />

      <div className={styles.content}>
        <Aside />

        <main className={styles.main}>
          {isInformationPage ? (
            children
          ) : (
            <Card size={CardSize.Small}>{children}</Card>
          )}
        </main>
      </div>
    </>
  );
};

export default Layout;
