import { Links, Page } from 'types/route';

const dynamicParameter = '{{ id }}';

export const defaultUrl = `${Links.company + Links.information}`;

export const informationLink = Links.company + Links.information;

export const detailingPages: Page[] = [
  {
    name: 'Статистика',
    href: Links.detailing + Links.statistics,
  },
  {
    name: 'Организации',
    href: Links.detailing + Links.organisations,
  },
  {
    name: 'Клиенты',
    href: Links.detailing + Links.clients,
  },
  // {
  //   name: 'Пользователи',
  //   href: Links.detailing + Links.users,
  // },
  // {
  //   name: 'Презентации',
  //   href: Links.presentations,
  // },
];

export const learningPages: Page[] = [
  {
    name: 'Информация',
    href: Links.information,
  },
];

export const dynamicPaths = {
  user: `${Links.users}`,

  department: `${Links.departments}`,

  presentation: `${Links.presentations}`,

  organisation: `${Links.organisations}`,

  client: `${Links.clients}`,

  statistics: `${Links.statistics}`,
};

export const linksToCreationPage = {
  detailing_organisation: `${Links.detailing}${Links.organisations}/new`,
  detailing_client: `${Links.detailing}${Links.clients}/new`,
};

type Paths = keyof typeof dynamicPaths;

const pathsKeys = Object.keys(dynamicPaths) as Paths[];

export const dynamicLinks = pathsKeys.reduce((links, path) => {
  return {
    ...links,
    [path]: `${dynamicPaths[path]}/${dynamicParameter}`,
  };
}, {}) as {
  [k in Paths]: string;
};

interface GetDynamicLinkProps {
  link?: string;
  id: string;
}

export const getDynamicLink = ({ link, id }: GetDynamicLinkProps) => {
  if (!link) return;

  return link.replace(dynamicParameter, id);
};
