import { BackendError } from 'types/api';

export const redirectToErrorPage = ({ status }: BackendError) => {
  const isCheck = Number(status) >= 400;

  if (isCheck) {
    if (status) {
      switch (status) {
        case 401: {
          window.location.assign(
            `${process.env.NEXT_PUBLIC_ID_URL}?redirect=${window.location.host}`,
          );

          break;
        }

        case 404: {
          window.location.assign(`${window.location.origin}/404`);

          break;
        }

        case 403: {
          window.location.assign(`${window.location.origin}/403`);

          break;
        }

        case 503: {
          window.location.assign(`${window.location.origin}/503`);

          break;
        }

        default: {
          window.location.assign(`${window.location.origin}/unknown`);
        }
      }
    }
  }
};
