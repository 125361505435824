import Image from 'next/image';
import Link from 'next/link';
import * as React from 'react';

import ArrowIcon from 'public/static/images/aside-arrow.svg';
import DetailingIcon from 'public/static/images/detailing-logo.svg';

import { useGetUserQuery } from 'store/api';

import { useCustomRouter } from 'hooks';

import Card from 'components/card';
import { CardSize } from 'components/card/type';

import { ButtonBlue } from 'ui-kit/button';

import { detailingPages, informationLink } from 'constants/links';

import { getRequestFeatureLink } from 'utils/links';

import styles from './aside.module.scss';

const Aside: React.FC = () => {
  const { pathname } = useCustomRouter();

  const [isOpen, setIsOpen] = React.useState(true);

  const { data: userInformation } = useGetUserQuery();

  const handleToggle = () => {
    setIsOpen((prevState) => !prevState);
  };

  const isActiveInformation = pathname.includes(informationLink);

  if (!userInformation) return null;

  return (
    <aside data-open={isOpen} className={styles.aside}>
      <Card className={styles.wrapper} size={CardSize.Small}>
        {isOpen ? (
          <React.Fragment>
            <Link href={informationLink} legacyBehavior>
              <span
                data-active={isActiveInformation}
                className={styles.navigate}>
                Информация
              </span>
            </Link>

            <hr className={styles.separator} />

            <div className={styles.icon}>
              <Image
                src={DetailingIcon}
                width={185}
                height={26}
                unoptimized
                alt="detailing-icon"
              />
            </div>

            <ul className={styles.list}>
              {detailingPages.map((page) => {
                const { name, href } = page;

                const isActive = pathname.includes(href);

                return (
                  <li key={href} className={styles.item}>
                    <Link href={href} legacyBehavior>
                      <span data-active={isActive} className={styles.navigate}>
                        {name}
                      </span>
                    </Link>
                  </li>
                );
              })}
            </ul>

            <div className={styles.information}>
              <p className={styles.informationText}>
                Появились вопросы или у вас есть предложения по улучшению работы
                сервиса?
              </p>

              <ButtonBlue
                popupId={getRequestFeatureLink(userInformation)}
                className={styles.informationButton}>
                Заполнить форму
              </ButtonBlue>
            </div>
          </React.Fragment>
        ) : null}

        <button className={styles.buttonContainer} onClick={handleToggle}>
          <div className={styles.buttonIcon}>
            <Image
              src={ArrowIcon}
              width={24}
              height={12}
              alt="arrow"
              unoptimized
            />
          </div>
        </button>
      </Card>
    </aside>
  );
};

export default Aside;
