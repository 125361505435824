import Image from 'next/image';
import * as React from 'react';

import { Typography } from '@mui/material';
import { getFullUrl } from 'maven-ui-kit/core';

import MavenLogo from 'public/static/images/maven-logo.svg';
import UserIcon from 'public/static/images/user.svg';

import { useGetUserQuery } from 'store/api';

import { useCustomRouter } from 'hooks';

import styles from './header.module.scss';

const Header: React.FC = () => {
  const { push } = useCustomRouter();

  const { data: userInformation } = useGetUserQuery();

  const handleGoID = () => {
    const url = process.env.NEXT_PUBLIC_ID_URL ?? '';

    push(url);
  };

  if (!userInformation) return null;

  const { organisation, firstName, secondName } = userInformation;

  const { headerLogo } = organisation.logos;

  return (
    <header className={styles.header}>
      {headerLogo ? (
        <div className={styles.logo}>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            alt="user-logo"
            src={getFullUrl(organisation.logos.headerLogo)}
          />
        </div>
      ) : (
        <Typography variant="h2">{organisation.name}</Typography>
      )}

      <div className={styles.userInformation}>
        <div className={styles.userName} onClick={handleGoID}>
          <div className={styles.userIcon}>
            <Image alt="user-icon" src={UserIcon} />
          </div>
          {secondName} {firstName}
        </div>

        <hr className={styles.separate} />

        <Image alt="maven-icon" src={MavenLogo} />
      </div>
    </header>
  );
};

export default Header;
